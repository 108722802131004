import React from 'react';

const Inventory = () => {
    return (
        <div>
            <h2>This is an Inventory</h2>
        </div>
    );
};

export default Inventory;